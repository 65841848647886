//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/v3'
import { teamsStore, uiStore } from '@/store'

export default {
  name: 'TeamStatisticModal',
  components: {
    ChartComponent: () => import('./BarChar'),
  },
  data: () => {
    return {
      chartPeriod: 1,
      chartsData: {
        activeUsers: {
          name: '',
          data: null,
        },
        totalUsers: {
          name: '',
          data: null,
        },
        newUsers: {
          name: '',
          data: null,
        },
        newMessages: {
          name: '',
          data: null,
        },
        newCalls: {
          name: '',
          data: null,
        },
      },
      periodsOptions: [],
    }
  },
  created () {
    this.periodsOptions = [{ label: this.$t('team.statistic.month'), value: 1 },
      { label: this.$t('team.statistic.threeMonths'), value: 3 },
      { label: this.$t('team.statistic.halfYear'), value: 6 },
      { label: this.$t('team.statistic.year'), value: 12 },
    ]
    this.chartsData.activeUsers.name = this.$t('team.statistic.activeUsers')
    this.chartsData.totalUsers.name = this.$t('team.statistic.totalUsers')
    this.chartsData.newUsers.name = this.$t('team.statistic.newUsers')
    this.chartsData.newMessages.name = this.$t('team.statistic.newMessages')
    this.chartsData.newCalls.name = this.$t('team.statistic.newCalls')
    this.loadData()
  },
  methods: {
    inputChartPeriod (val) {
      this.chartPeriod = val
      this.chartsData.activeUsers.data = null
      this.chartsData.totalUsers.data = null
      this.chartsData.newUsers.data = null
      this.chartsData.newMessages.data = null
      this.chartsData.newCalls.data = null
      this.loadData()
    },
    loadData () {
      api.teams.activeUsers(null, this.chartPeriod).then(r => {
        this.chartsData.activeUsers.data = r
      })
      api.teams.totalUsers(null, this.chartPeriod).then(r => {
        this.chartsData.totalUsers.data = r
      })
      api.teams.newUsers(null, this.chartPeriod).then(r => {
        this.chartsData.newUsers.data = r
      })
      api.teams.newMessages(null, this.chartPeriod).then(r => {
        this.chartsData.newMessages.data = r
      })
      api.teams.newCalls(null, this.chartPeriod).then(r => {
        this.chartsData.newCalls.data = r
      })
    },
    closeModal () {
      uiStore.actions.hideModal()
    },
  },
  computed: {
    name () {
      return teamsStore.getters.currentTeam.name
    },
  },
}
